import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import {
    PageStyle,
} from '../components/style'

export default function Template({
     data,
}) {
    const { markdownRemark } = data
    const { frontmatter,html } = markdownRemark
    return (
        <PageStyle>
            <Layout pageTitle={frontmatter.title}></Layout>
            <div dangerouslySetInnerHTML={{__html: html}}></div>
        </PageStyle>
    )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
